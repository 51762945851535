import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";

export const Root = styled.div`
    font-size: 16px;
    line-height: 1.25;
    color: ${Color.Neutral100};
    padding-bottom: 16px;
`;

export const ExcerptWrapper = styled.p`
    padding-bottom: 16px;
    color: ${Color.Neutral60};
`;
