import * as React from "react";
import { useTranslation } from "react-i18next";
import { Root, ExcerptWrapper } from "./BlogPostPreview.styles";
import { RouterLink } from "../RouterLink/RouterLink";
import { Color } from "../../layout/GlobalStyles/Color";
import { Subtitle } from "../Footer/Footer.styles";

interface IProps {
    readonly id: string;
    readonly excerpt: string;
    readonly timeToRead: number;
    readonly frontmatter: {
        readonly date: string;
        readonly slug: string;
        readonly title: string;
        readonly description: string;
        readonly author: string;
        readonly tag: string;
    };
}

export const BlogPostPreview: React.FC<React.PropsWithChildren<IProps>> = ({ excerpt, frontmatter, children, timeToRead }) => {
    const { title, slug, date, author, tag } = frontmatter;
    const [t] = useTranslation();
    return (
        <Root>
            <RouterLink href={slug}>{children}</RouterLink>
            <RouterLink href={slug} fontSize="24px" color={Color.Neutral100} hoverColor={Color.Neutral90}>
                {title}
            </RouterLink>
            <Subtitle>
                {date} • {author} • {tag} • {t("blogIndexPage.readingTime", { minutes: timeToRead })}
            </Subtitle>
            <ExcerptWrapper>{excerpt}</ExcerptWrapper>
            <RouterLink href={slug}>{t("blogIndexPage.readMore")} →</RouterLink>
        </Root>
    );
};
